import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';
// ----------------------------------------------------------------------
const getCharAtName = (name) => name && name.charAt(0).toUpperCase();
const getColorByName = (name) => {
    if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name)))
        return 'primary';
    if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name)))
        return 'info';
    if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name)))
        return 'success';
    if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name)))
        return 'warning';
    if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name)))
        return 'error';
    return 'default';
};
// ----------------------------------------------------------------------
const CustomAvatar = forwardRef(({ color, name = '', BadgeProps, children, sx, ...other }, ref) => {
    const theme = useTheme();
    const charAtName = getCharAtName(name);
    const colorByName = getColorByName(name);
    const colr = color || colorByName;
    const renderContent = colr === 'default' ? (_jsxs(Avatar, { ref: ref, sx: sx, ...other, children: [name && charAtName, children] })) : (_jsxs(Avatar, { ref: ref, sx: {
            color: theme.palette[colr]?.contrastText,
            backgroundColor: theme.palette[colr]?.main,
            fontWeight: theme.typography.fontWeightMedium,
            ...sx,
        }, ...other, children: [name && charAtName, children] }));
    return BadgeProps ? (_jsx(Badge, { overlap: "circular", anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, ...BadgeProps, children: renderContent })) : (renderContent);
});
export default CustomAvatar;
